<template>
  <div class="shop-collecction">
    <NavBar class="nav-nar">
      <div slot="title">店铺收藏</div>
    </NavBar>
    <div class="empty-box" v-if="list.length <= 0">
      <div class="empty-layout">
        <div class="img-box">
          <img
            src="~@/assets/common/collect-blank-images@2x.png"
            class="empty-img"
          />
        </div>
        <div class="empty-text">你还没有任何收藏~</div>
        <div class="button">
          <van-button type="danger" round block @click="$router.push('/home')">
            去逛逛吧
          </van-button>
        </div>
      </div>
    </div>
    <div v-else class="padding">
      <div
        class="list-box"
        v-for="(shop, index) in list"
        :key="index"
        @click="onClick(shop.shop_id)"
      >
        <div class="head-portrait">
          <!-- 头像图片 -->
          <img :src="shop.shop_logo" class="head-img" />
        </div>
        <div class="bottom-border">
          <div class="info-layout">
            <div class="shop-content">
              <span class="shop-title">{{ shop.shop_name }}</span>
              <div class="shop-heat">
                <div class="back-color">
                  <van-rate
                    v-model="shop.shop_score"
                    size="10"
                    gutter="2"
                    void-icon="star"
                    color="#ED301D"
                    void-color="#EAEAEA"
                    :readonly="true"
                  />
                </div>
                <span class="heat">{{ shop.collect_num }}人关注</span>
              </div>
            </div>
          </div>
          <div
            class="delete-button"
            @click="setShopCollect(shop.shop_id, index)"
            @click.stop
          >
            <!-- <van-button
              type="danger"
              @click="setShopCollect(shop.shop_id, index)"
              @click.stop
              round
              block
              color="linear-gradient(to right,  #FE5876,#ED301D)"
            >
              删除
            </van-button> -->
            删除
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/layout/nav-bar/nav-bar";
import { getCollectList, setShopCollect } from "@/api/shop";
import tools from "@/common/js/tools";
import { Toast } from "vant";
export default {
  name: "shop-collection",
  components: {
    NavBar,
  },
  data() {
    return {
      rateValue: 3,
      list: [],
      page: 1,
      total: 100,
    };
  },
  mounted() {
    this.getShopList();
  },
  methods: {
    onClick(id) {
      this.$router.push({ name: "shop-home", query: { shopId: id } });
    },
    async getShopList() {
      if (this.list.length >= this.total) {
        return false;
      }
      const ret = await getCollectList({ page: this.page });
      if (ret.code * 1 == 1) {
        this.list = this.list.concat(ret.data.items);
        this.total = ret.data.total;
        ++this.page;
      }
    },
    async setShopCollect(shop_id, index) {
      const ret = await setShopCollect({ shop_id: shop_id, is_collect: 0 });
      if (ret.code * 1 == 1) {
        this.list.splice(index, 1);
        --this.total;
        Toast.success(ret.msg);
      } else {
        Toast.fail(ret.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.back-color {
  display: flex;
  // justify-content: center;
  align-items: center;
  // box-sizing: border-box;
  // width: 82px;
  // height: 20px;
  background-color: #f5f5f5;
  // opacity: 0.7;
  padding: 2px 8px;
  border-radius: 100px;
}
.nav-nar {
  border-bottom: 1px solid #ededed;
}
.shop-collecction {
  background-color: #fff;
  height: 100vh;
}
.padding {
  padding-top: 58px;
}
// 空页面的样式
.empty-box {
  position: relative;
  width: 100vw;
  height: 100%;
  padding: 58px 0 0 0;

  .empty-layout {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    .img-box {
      width: 220px;
      .empty-img {
        display: block;
        width: 100%;
      }
    }
  }

  .empty-text {
    margin: 28px 0 19px 0;
    font-size: 18px;
    color: #aaaaaa;
  }

  .button {
    width: 210px;
  }
}

// 有内容页面的样式
.list-box {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 12px 0 12px;
  box-sizing: border-box;
  .bottom-border {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 12px;
    border-bottom: 1px solid rgb(245, 245, 245);
  }
  .info-layout {
    display: flex;
  }

  .head-portrait {
    width: 52px;
    height: 52px;

    .head-img {
      width: 100%;
      border-radius: 50%;
    }
  }

  .shop-content {
    padding-left: 12px;

    .shop-title {
      font-size: 18px;
      font-weight: 500;
    }

    .shop-heat {
      display: flex;
      margin-top: 10px;

      .heat {
        font-size: 13px;
        color: #aaaaaa;
        margin-left: 20px;
      }
    }
  }

  .delete-button {
    width: 65px;
    height: 28px;
    background: linear-gradient(260deg, #ed301d 0%, #fe5876 100%);
    opacity: 1;
    border-radius: 19px;
    text-align: center;
    line-height: 28px;
    font-size: 16px;
    color: #fff;
    // .van-button {
    //   height: 100%;
    // }
  }
}
// .van-button__text {
//   font-size: 21px;
// }
</style>
